import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FC } from 'react';
import { useMediaLayout } from 'use-media';

import { TimelineCard } from '../styles/common';
import { ViewportSize } from '../utils/media-queries-utils';
import { Step, Timeline } from './Timeline';
import { H3, Typography } from './Typography';

export const FilerInstructions: FC = () => {
  const staticData = useStaticQuery(graphql`
    query {
      eFiler2: file(relativePath: { eq: "e-filer-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eFiler3: file(relativePath: { eq: "e-filer-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eFiler4: file(relativePath: { eq: "e-filer-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eFiler5: file(relativePath: { eq: "e-filer-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eFiler6: file(relativePath: { eq: "e-filer-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const isWide = useMediaLayout({ minWidth: ViewportSize.laptop });

  return (
    <Timeline className="mb-16">
      <Step>
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1 mr-0 lg:mr-20">
            <H3>1.) Go to the court website</H3>
            <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
              Go to the court website at https://www.appeals2.az.gov (if already logged-in, skip to
              step 5).
            </Typography>
          </div>
        </div>
      </Step>

      <Step>
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1 mr-0 lg:mr-20">
            <H3>2.) Click on the E-Filing</H3>
            <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
              Click on the E-Filing “star” in the top navigation area:
            </Typography>
          </div>
          <div className="flex-1">
            <TimelineCard style={{ height: 'auto' }} isWide={isWide} className="p-4 items-center">
              <div className="w-full">
                <Img
                  style={{ maxWidth: '100%' }}
                  className="rounded-sm lg:rounded-none"
                  fluid={staticData.eFiler2.childImageSharp.fluid}
                />
              </div>
            </TimelineCard>
          </div>
        </div>
      </Step>

      <Step>
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1 mr-0 lg:mr-20">
            <H3>3.) Click on “Login”</H3>
            <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
              Click on “Login” in the gold-colored left navigation buttons:
            </Typography>
          </div>
          <div className="flex-1">
            <TimelineCard style={{ height: 'auto' }} isWide={isWide} className="p-4 items-center">
              <div className="w-full">
                <Img
                  style={{ maxWidth: '100%' }}
                  className="rounded-sm lg:rounded-none"
                  fluid={staticData.eFiler3.childImageSharp.fluid}
                />
              </div>
            </TimelineCard>
          </div>
        </div>
      </Step>

      <Step>
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1 mr-0 lg:mr-20">
            <H3>4.) Log-in to E-filer</H3>
            <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
              Log-in to E-filer with your bar/ID number and password. Once successfully logged-in:
            </Typography>
          </div>
          <div className="flex-1">
            <TimelineCard style={{ height: 'auto' }} isWide={isWide} className="p-4 items-center">
              <div className="w-full">
                <Img
                  style={{ maxWidth: '100%' }}
                  className="rounded-sm lg:rounded-none"
                  fluid={staticData.eFiler4.childImageSharp.fluid}
                />
              </div>
            </TimelineCard>
          </div>
        </div>
      </Step>

      <Step>
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1 mr-0 lg:mr-20">
            <H3>5.) Go to “My Profile”</H3>
            <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
              Click “My Profile” in the gold-colored left navigation buttons:
            </Typography>
          </div>
          <div className="flex-1">
            <TimelineCard style={{ height: 'auto' }} isWide={isWide} className="p-4 items-center">
              <div className="w-full">
                <Img
                  style={{ maxWidth: '100%' }}
                  className="rounded-sm lg:rounded-none"
                  fluid={staticData.eFiler5.childImageSharp.fluid}
                />
              </div>
            </TimelineCard>
          </div>
        </div>
      </Step>

      <Step>
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1 mr-0 lg:mr-20">
            <H3>6.) Locate the HyperCite key</H3>
            <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
              Locate the HyperCite key at the top, right above the “e-Filer Profile Information”
              heading, and below the “Current Filer” and “Bar Number” fields. If copy-pasting the
              key, be sure to avoid inadvertently grabbing spaces. Also, so that there is no
              confusion—if you see zeroes, they’re zeroes; never the letter “O.” They key is based
              on hexadecimal values:
            </Typography>
          </div>
          <div className="flex-1">
            <TimelineCard
              style={{ height: 'auto', marginTop: 0 }}
              isWide={isWide}
              className="p-4 items-center">
              <div className="w-full">
                <Img
                  style={{ maxWidth: '100%' }}
                  className="rounded-sm lg:rounded-none"
                  fluid={staticData.eFiler6.childImageSharp.fluid}
                />
              </div>
            </TimelineCard>
          </div>
        </div>
      </Step>
    </Timeline>
  );
};
