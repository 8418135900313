import random from 'lodash/random';
import { either, isEmpty, isNil, pathOr } from 'ramda';

export const isEmptyOrNil = either(isNil, isEmpty);
export const getBodyMessage = pathOr('Something went wrong, please try again later', ['message']);

export function generateGuestToken(): string {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  return Array.from(Array(random(15, 25)), () => charset.charAt(random(0, charset.length))).join(
    '',
  );
}

export function tryAutomaticDownload(url: string): void {
  try {
    if (typeof window !== 'undefined') {
      const element = document.createElement('a');
      element.setAttribute('href', url);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  } catch {
    console.error('Could not automatically download file');
  }
}
