import styled from 'styled-components';

import { Card } from '../components/Card';
import { H1 } from '../components/Typography';
import { Device } from '../utils/media-queries-utils';

export const Title = styled(H1)`
  color: #f5f6ff;
  font-weight: 300;
  max-width: 900px;

  @media ${Device.tablet} {
    padding 0 10px;
  }
`;

export const Paragraph = styled.p`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333;
  font-size: 14px;
`;

export const TimelineCard = styled(Card)<{ isWide?: boolean }>`
  max-width: 770px;
  width: 100%;
  height: 530px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: ${({ isWide }) => (isWide ? -95 : 30)}px;
  min-width: ${({ isWide }) => (isWide ? 670 : 0)}px;
`;
