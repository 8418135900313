import { useLocation } from '@reach/router';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FiCheckCircle, FiXOctagon } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { FilerInstructions } from '../components/FilerInstructions';
import { Input } from '../components/Input';
import { Separator } from '../components/Separator';
import { H1, H3, H4 } from '../components/Typography';
import { updateFilerInfo } from '../lib/actions/me';
import { RootState } from '../reducers';
import { getBodyMessage } from '../utils/function-utils';
import { Container } from './styles';

const HelpText = styled.span`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333
  margin: 0 0 14px;
`;

interface P {
  onComplete?: () => void;
}

interface FormValues {
  key: string;
  bar: string;
}

export const FilerForm: FC<P> = (props) => {
  const isKeySet = useSelector((root: RootState) => root.me.me?.isLicenseKeySet);
  const barNumber = useSelector((root: RootState) => root.me.me?.barNumber);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({ defaultValues: { key: '', bar: barNumber || '' } });

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  async function onSubmit(values: FormValues) {
    try {
      await dispatch(updateFilerInfo(values.bar, values.key));

      toast.success('Filer key updated successfully.', {
        icon: <FiCheckCircle className="min-w-full w-8" size={32} color="#3CB043" />,
      });
      props.onComplete?.();
    } catch (err) {
      toast.error(getBodyMessage(err), {
        icon: <FiXOctagon className="min-w-full w-8" size={32} color="#D0312D" />,
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full" style={{ maxWidth: '100%' }}>
        <H1 className="mb-6">Authenticate an e-filer key</H1>
        {queryParams.has('requiresKey') && (
          <H3>In order to upload documents, we need your e-Filer license key</H3>
        )}

        <Input
          label="Bar number"
          className="mb-4"
          error={!!errors.key}
          helpText={errors.key && <HelpText>Bar number is required</HelpText>}
          inputStyle={{ color: '#333' }}
          placeholder="Bar number"
          style={{ maxWidth: 500 }}
          {...register('bar', { required: true })}
        />

        <Input
          label="e-Filer key"
          className="mb-4"
          error={!!errors.key}
          helpText={errors.key && <HelpText>Key is required</HelpText>}
          inputStyle={{ color: '#333' }}
          placeholder="0000-0000-0000-0000"
          style={{ maxWidth: 500 }}
          {...register('key', { required: true, pattern: /([A-F0-9\-])+/ })}
        />

        <div className="flex mt-4">
          <Button type="submit" disabled={isSubmitting}>
            {isKeySet ? 'Update' : 'Submit'}
          </Button>
        </div>

        <Separator className="mt-16 mb-6" />

        <H4 className="mb-12 text-center lg:text-left" style={{ fontSize: 20 }}>
          How it Works
        </H4>

        <FilerInstructions />
      </Container>
    </form>
  );
};
