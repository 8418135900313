import { Dispatch } from 'redux';

import { Api } from '../../api/api';
import { createAuthorizationHeader } from '../../api/utils';
import ActionTypes from '../actionTypes';
import { SdkAction, createSdkAction, onFulfill, onPending, onReject } from '../utils/actions';

export function login(email: string, password: string): SdkAction {
  return createSdkAction(ActionTypes.LOGIN, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.LOGIN) });

      return sdk
        .request('/login', {
          body: { password, email },
          method: 'post',
        })
        .then((res) => {
          sdk.setToken({
            token: {
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            },
          });

          dispatch({ type: ActionTypes.SESSION_ON });
          dispatch({ type: onFulfill(ActionTypes.LOGIN), payload: res.data });
        })
        .catch((res) => {
          dispatch({ type: ActionTypes.SESSION_OFF });
          dispatch({ type: onReject(ActionTypes.LOGIN) });

          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function getMe(): SdkAction {
  return createSdkAction(ActionTypes.GET_CURRENT_USER, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.GET_CURRENT_USER) });

      return sdk
        .request('/users/me', { method: 'get' })
        .then((res) => {
          dispatch({ type: onFulfill(ActionTypes.GET_CURRENT_USER), payload: res.data });
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.GET_CURRENT_USER) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function refreshSession(): SdkAction {
  return createSdkAction(ActionTypes.SESSION, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.SESSION) });

      return sdk
        .getToken()
        .then(({ refreshToken }) => {
          return sdk
            .request('/token/refresh', {
              headers: createAuthorizationHeader({}, refreshToken!),
              method: 'post',
              requiresAuthentication: true,
            })
            .then((res) => {
              dispatch({ type: onFulfill(ActionTypes.SESSION), payload: res.data });

              return sdk.setToken({
                token: {
                  accessToken: res.data.access_token,
                  refreshToken: res.data.refresh_token,
                },
              });
            })
            .catch(() => {
              dispatch({ type: ActionTypes.SESSION_OFF });
              dispatch({ type: onReject(ActionTypes.SESSION) });

              return Promise.reject('Invalid token');
            });
        })
        .catch(() => {
          dispatch({ type: ActionTypes.SESSION_OFF });
          dispatch({ type: onReject(ActionTypes.SESSION) });

          return Promise.reject('Inexistent token');
        });
    },
  });
}

export function logout(): SdkAction {
  return createSdkAction(ActionTypes.LOGOUT, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.LOGOUT) });

      return sdk
        .getToken()
        .then(({ refreshToken }) => {
          return sdk
            .request('/logout', {
              headers: createAuthorizationHeader({}, refreshToken!),
              method: 'post',
              requiresAuthentication: true,
            })
            .then(() => {
              dispatch({ type: onFulfill(ActionTypes.LOGOUT) });
              dispatch({ type: ActionTypes.SESSION_OFF });

              return sdk.removeToken();
            })
            .catch(() => {
              dispatch({ type: ActionTypes.SESSION_OFF });
              dispatch({ type: onReject(ActionTypes.LOGOUT) });

              return Promise.reject();
            });
        })
        .catch(() => {
          dispatch({ type: ActionTypes.SESSION_OFF });
          dispatch({ type: onReject(ActionTypes.LOGOUT) });

          return Promise.reject();
        });
    },
  });
}

type CreateUserInput = {
  email: string;
  password: string;
  bar_number: string;
  licence_key: string;
};

export function createUser(input: CreateUserInput): SdkAction {
  return createSdkAction(ActionTypes.CREATE_USER, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.CREATE_USER) });

      return sdk
        .request('/users/register', {
          body: {
            email: input.email,
            password: input.password,
            bar_number: input.bar_number,
            licence_key: input.licence_key,
          },
          method: 'post',
        })
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.CREATE_USER),
            payload: res.data,
          });
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.CREATE_USER) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function verifyAccount(token: string): SdkAction {
  return createSdkAction(ActionTypes.VERIFY_ACCOUNT, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.VERIFY_ACCOUNT) });

      return sdk
        .request(`/verification/${token}`, {
          method: 'post',
        })
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.VERIFY_ACCOUNT),
            payload: res.data,
          });
          return Promise.resolve();
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.VERIFY_ACCOUNT) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function requestVerification(email: string): SdkAction {
  return createSdkAction(ActionTypes.REQUEST_VERIFICATION, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.REQUEST_VERIFICATION) });

      return sdk
        .request(`/verification`, {
          method: 'post',
          body: { email },
        })
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.REQUEST_VERIFICATION),
            payload: res.data,
          });
          return Promise.resolve();
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.REQUEST_VERIFICATION) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function updateFilerInfo(barNumber: string, licenseKey: string): SdkAction {
  return createSdkAction(ActionTypes.UPDATE_FILER_INFO, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.UPDATE_FILER_INFO) });

      return sdk
        .request('/users/me', {
          body: { bar_number: barNumber, licence_key: licenseKey },
          method: 'put',
          requiresAuthentication: true,
        })
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.UPDATE_FILER_INFO),
            payload: res.data,
          });
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.UPDATE_FILER_INFO) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function forgotPassword(email: string): SdkAction {
  return createSdkAction(ActionTypes.FORGOT_PASSWORD, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.FORGOT_PASSWORD) });

      return sdk
        .request('/password-reset', {
          body: { email },
          method: 'post',
        })
        .then((res) => {
          dispatch({ type: onFulfill(ActionTypes.FORGOT_PASSWORD), payload: res.data });
          return Promise.resolve(res.data);
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.FORGOT_PASSWORD) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function resetPassword(token: string, password: string): SdkAction {
  return createSdkAction(ActionTypes.RESET_PASSWORD, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.RESET_PASSWORD) });

      return sdk
        .request(`/password-reset/${token}`, {
          body: { password },
          method: 'post',
        })
        .then((res) => {
          dispatch({ type: onFulfill(ActionTypes.RESET_PASSWORD), payload: res.data });
          return Promise.resolve(res.data);
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.RESET_PASSWORD) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}
