import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Paths } from '../paths';
import { Device } from '../utils/media-queries-utils';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.p`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;

  color: black;
  padding: 10px;
`;

const Anchor = styled(Link)`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;

  color: black;
  padding: 10px;
`;

const FooterContainer = styled.div`
  height: 80px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border-top: 1px solid #e0e0e0;
  margin-left: 110px;
  margin-right: 110px;

  ${Row} {
    flex: 1;
    justify-content: center;
  }

  ${Row}:first-child,
  ${Row}:last-child {
    flex: 0;
  }

  ${Row}:first-child {
    justify-content: flex-start;
  }

  ${Row}:last-child {
    justify-content: flex-end;
  }

  @media ${Device.laptop} {
    margin-left: 55px;
    margin-right: 55px;
  }

  @media ${Device.tablet} {
    flex-direction: column;
  }
`;

export const Footer: FC = () => {
  return (
    <FooterContainer>
      <Row style={{ minWidth: 340 }} className="mb-4 md:mb-0 lg:mb-0 mt-4 md:mt-0 lg:mt-0">
        <Text className="mr-6 mb-0">
          HyperCite<sup>&reg;</sup>
        </Text>
        <Text className="mb-0">Patent Pending - All Rights Reserved 2020</Text>
      </Row>
      <Row style={{ minWidth: 275 }} className="mb-4 md:mb-0 lg:mb-0">
        <Anchor className="mr-6" to={Paths.PrivacyPolicy}>
          Privacy Policy
        </Anchor>
        <Anchor to={Paths.TermsOfUse}>Terms of Use</Anchor>
      </Row>
    </FooterContainer>
  );
};
