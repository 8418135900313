import React, {
  CSSProperties,
  FC,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  forwardRef,
  isValidElement,
} from 'react';
import { useUID } from 'react-uid';
import styled from 'styled-components';

import { isEmptyOrNil } from '../utils/function-utils';
import { Colors } from '../utils/style-utils';
import { Label } from './Label';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | React.MutableRefObject<HTMLInputElement | null>
    | null;
  label?: ReactElement | string;
  helpText?: ReactElement | string;
  fullWidth?: boolean;
  inputStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  error?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextInput = styled.input<InputProps>`
  padding: 10px;
  background: ${({ error }) => (error ? 'rgba(235, 87, 87, 0.05)' : 'white')};
  border: ${({ error }) => (error ? '1px solid #eb5757' : '1px solid #b7bad6')};
  box-sizing: border-box;
  border-radius: 5px;
  color: ${Colors.Blue500};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
`;

const HelpText = styled.span`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333;
  margin: 14px 0;
`;

function renderHelpText(helpText: InputProps['helpText']): ReactNode | string | null {
  if (isEmptyOrNil(helpText)) {
    return null;
  }

  if (typeof helpText === 'string') {
    return <HelpText>{helpText}</HelpText>;
  }

  if (isValidElement(helpText)) {
    return helpText;
  }

  throw new Error('FileInput: Invalid render text prop');
}

function renderLabel(label: InputProps['label']): ReactNode | string | null {
  if (isEmptyOrNil(label)) {
    return null;
  }

  if (typeof label === 'string') {
    return <Label>{label}</Label>;
  }

  if (isValidElement(label)) {
    return label;
  }

  throw new Error('Input: Invalid render label prop');
}

export const Input: FC<InputProps> = forwardRef((props, ref) => {
  const uid = useUID();
  const { label, helpText, inputStyle, containerStyle, ...rest } = props;

  return (
    <Container style={containerStyle}>
      {renderLabel(label)}

      <TextInput ref={ref} style={inputStyle} id={uid} {...rest} />

      {renderHelpText(helpText)}
    </Container>
  );
});

Input.defaultProps = {
  label: '',
  helpText: '',
  fullWidth: false,
  inputStyle: {},
};
