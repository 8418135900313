import Dialog from 'rc-dialog';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import styled, { createGlobalStyle } from 'styled-components';

import { useModal } from '../contexts/filerModal';
import { FilerForm } from '../forms/FilerForm';
import { Colors } from '../utils/style-utils';
import { Footer } from './Footer';
import { Header } from './Header';

const GlobalStyle = createGlobalStyle`
  .rc-dialog-title {
    font-family: 'Norse', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: black;
  }

  .rc-dialog-body td {
    font-family: 'Norse', sans-serif;
    font-style: normal;
    font-weight: normal;
    color: black;
    padding: 0.5rem;

    &:first-child {
      width: 40%;
    }

    &:last-child {
      width: 60%;
    }
  }

  .rc-dialog-body tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const LayoutContainer = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-color: white;
`;

export const Layout: FC<{ isAuthenticated?: boolean }> = ({ children, isAuthenticated }) => {
  const { visible, close } = useModal();
  return (
    <>
      <GlobalStyle />
      <LayoutContainer className="flex flex-col">
        <Header isAuthenticated={isAuthenticated} />
        <div style={{ minHeight: 'calc(100vh - 160px)' }}>{children}</div>
        <Footer />
      </LayoutContainer>

      <Dialog
        closable
        maskClosable
        animation="zoom"
        maskAnimation="fade"
        wrapClassName="modal-center"
        closeIcon={<FiX size={32} color={Colors.Blue900} />}
        onClose={close}
        visible={visible}
        bodyStyle={{ padding: 0 }}
        style={{ maxWidth: '90vw', width: '100%' }}>
        <FilerForm onComplete={close} />
      </Dialog>
    </>
  );
};
