import styled from 'styled-components';

import { Device } from '../utils/media-queries-utils';

export const Container = styled.div`
  max-width: 820px;
  padding: 60px 50px;

  @media ${Device.laptop} {
    padding: 30px 25px;
  }
`;

export const HelpText = styled.span<{ positive?: boolean; negative?: boolean }>`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: ${({ positive, negative }) => {
    if (positive) {
      return '#219653';
    }

    if (negative) {
      return '#EB5757';
    }

    return '#333';
  }};
  margin-top: 14px;
  margin-bottom: 14px;
`;

export const Loading = styled.div`
  position: absolute;
  background-color: white;
  z-index: 90;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
