export const ViewportSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const Device = {
  mobileS: `only screen and (max-width: ${ViewportSize.mobileS})`,
  mobileM: `only screen and (max-width: ${ViewportSize.mobileM})`,
  mobileL: `only screen and (max-width: ${ViewportSize.mobileL})`,
  tablet: `only screen and (max-width: ${ViewportSize.tablet})`,
  laptop: `only screen and (max-width: ${ViewportSize.laptop})`,
  laptopM: `only screen and (max-width: ${ViewportSize.laptopM})`,
  laptopL: `only screen and (max-width: ${ViewportSize.laptopL})`,
  desktop: `only screen and (max-width: ${ViewportSize.desktop})`,
  desktopL: `only screen and (max-width: ${ViewportSize.desktop})`,
};
