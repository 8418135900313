import { isNil } from 'lodash';
import { pathOr } from 'ramda';

import { RootState } from '../reducers';
import { Me } from '../reducers/me';

export const userIdSelector = (state: RootState): number | null =>
  pathOr(null, ['me', 'id'], state.me);

export const requiredInfoForUploadingSelector = (state: RootState): boolean => {
  const { me } = state.me;
  if (isNil(me)) return false;
  const { isLicenseKeySet, barNumber } = me;
  return [isLicenseKeySet, barNumber].filter(Boolean).length === 2;
};

export function userSelector(state: RootState): Me | null {
  return state.me.me;
}
