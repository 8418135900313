import styled from 'styled-components';

export interface CardProps {
  hero?: boolean;
}

export const Card = styled.section<CardProps>`
  max-width: 1280px;
  background-color: ${({ hero }) => (hero ? '#F2F3F5' : '#04093D')};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
`;

Card.defaultProps = {
  hero: false,
};
